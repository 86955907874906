import React, { useContext } from "react";
import { Footer, TitleBar } from "Components";
import { AppContext } from "Contexts/AppContext/AppContext";
import { Icon } from "../../Components/Footer/Icon";
import ActionSheet, { Action } from "reactjs-action-sheet";

function ContactUsScreen() {
  const context = useContext(AppContext);
  const allChalets = context.getData();
  const contacts = context.getContactsData();

  return (
    <div style={{ flex: 1 }}>
      <TitleBar title="Contacts" />
      <div className="HomeScreenContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            flex: "1",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            alignSelf: "center",
          }}
        >
          <ReachWhatsapp contacts={contacts} />

          <ReachCall contacts={contacts} />

          <CardTile
            title="Reach us on Instagram"
            action={<Icon iconClass="fa fa-instagram" />}
            onClick={() => {
              window.open("https://www.instagram.com/karamchalet/");
            }}
          />

          <CardTile
            title="Visit the Website"
            action={<Icon iconClass="fa fa-globe" />}
            onClick={() => {
              window.open("https://karamchalet.com", "_self");
            }}
          />

          {/* <Contacts contacts={contacts} />
          <Instagram /> */}
        </div>
      </div>
      {allChalets && (
        <Footer whichChalet={allChalets?.[allChalets?.length - 1]?.key} />
      )}
    </div>
  );
}

export default ContactUsScreen;

const ReachWhatsapp = ({ contacts }) => {
  return (
    <ActionSheet
      actions={contacts?.map((contact) => {
        if (!contact.isWhatsapp) {
          return <div key={contact.number}></div>;
        }
        const formattedNumber = `+965 ${contact.number.slice(
          0,
          4
        )} ${contact.number.slice(4)}`;
        return (
          <Action
            key={formattedNumber}
            onClick={() => {
              window.open(`https://wa.me/965${contact.number}`);
            }}
          >
            {formattedNumber}
          </Action>
        );
      })}
    >
      <CardTile
        title="Reach us on Whatsapp"
        action={<Icon iconClass="fa fa-whatsapp" />}
      />
    </ActionSheet>
  );
};

const ReachCall = ({ contacts }) => {
  return (
    <ActionSheet
      actions={contacts?.map((contact) => {
        if (!contact.isCall) {
          return <div key={contact.number}></div>;
        }
        const formattedNumber = `+965 ${contact.number.slice(
          0,
          4
        )} ${contact.number.slice(4)}`;
        return (
          <a key={formattedNumber} href={`tel:+965${contact.number}`}>
            <Action>{formattedNumber}</Action>
          </a>
        );
      })}
    >
      <CardTile
        title="Reach us on Phone"
        action={<Icon iconClass="fa fa-phone" />}
      />
    </ActionSheet>
  );
};

const CardTile = ({ title, action, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: "#0f3e68",
        display: "flex",
        padding: "20px",
        borderRadius: "20px",
        gap: "38px",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
        cursor: "pointer",
        maxWidth: "400px",
        width: "100%",
        margin: "10px",
        boxSizing: "border-box",
      }}
    >
      <p
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "white",
          margin: 0,
        }}
      >
        {title}
      </p>

      <div>{action}</div>
    </div>
  );
};
